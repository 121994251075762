import Vue from 'vue'
import App from './App.vue'
import { routes } from './router'
import store from './store'
import VueRouter from 'vue-router'
import animate from 'animate.css'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import VideoPlayer from 'vue-video-player'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'

Vue.use(VideoPlayer)
Vue.use(ElementUI)

const router = new VueRouter({ routes })
new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
