import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

export const routes = [
  {
    path: '/',
    name: 'Overview',
    meta: {
      title: '订单可视化'
    },
    component: () => import('@/views/overview/index')
  },
  {
    path: '/button-view',
    name: 'ButtonView',
    meta: {
      title: '订单可视化操作'
    },
    component: () => import('@/views/buttonView/index')
  }
]
